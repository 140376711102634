$('input[type="file"]').change(function(e) {
    var fileName = e.target.files[0].name;
    $('.custom-file-label').html(fileName);
});

$('.our_board_member .img_wrapper').click(function() {
    $('.img_wrapper').removeClass('active');
    $(this).addClass('active');
});

//scroll To Element
$('.accordionOurStory .btn').click(function() {
    // $('html, body').animate({
    //     scrollTop: $('#' + $(this).data('scroll')).offset().top + 200
    // }, 400);
    window_wdth = window.innerWidth;
    if (window_wdth > 768) {
        // setTimeout(function() {
        //     window.reinitfullpage();
        // }, 300);
    } else {



        var $accordion = $('.accordion')

        $accordion.each(function(i) {
            $accordion.eq(i).on('shown.bs.collapse', function() {
                var panel = $(this).find('.show');

                $('html, body').animate({
                    scrollTop: panel.offset().top - 150
                }, 500)

            })


        })


    }
    // window.reinitfullpage();
})