var window_wdth;
$('html').addClass('mainsite');
const fullpage = require('fullpage.js')
initfullpage();
$(window).resize(function() {
    initfullpage();
});
function initTheFullPageFun() {
    let myFullpage = new fullpage('#fullpage', {
        anchors: menu_items,
        menu: '.navigation-menu .menu',
        scrollOverflow: true,
        scrollOverflowReset: false,
        slidesNavigation: true,
        keyboardScrolling: true,
        resize: false,
        animateAnchor: false,
        scrollBar: true,
        fitToSection:false,

        onLeave: function(origin, destination, direction) {
            var params = {
                origin: origin,
                destination: destination,
                direction: direction
            };


            var sec_id = params['destination'].item.id;

            $('.hamburger').removeClass('is-active');
            $('.navigation-menu').removeClass('is-active');
            $('.header').removeClass('is-active-menu');





            setTimeout(function() {
                $('#' + sec_id).addClass('active_section');
                // $('.active_section .fp-scroller').removeClass('resetscroll');
            }, 400);

            if ($('#' + sec_id).hasClass('sliding-section')) {

                $('.header').removeClass('smallLogo').addClass('sliding-header');

            } else {

                $('.header').addClass('smallLogo').removeClass('sliding-header');

            }

            if ((($('#' + sec_id).hasClass('section-whth-white'))) || (($('#' + sec_id).hasClass('exhibitions-section')))) {
                $('.header').addClass('whitelogo')

            } else {
                $('.header').removeClass('whitelogo')
            }
            if ($('#' + sec_id).hasClass('career-contact-section')) {
                $('.header').addClass('two-links-active');
            } else {
                $('.header').removeClass('two-links-active');

            }


        }




    })
}

function initfullpage() {
    window_wdth = window.innerWidth;
    if (window_wdth > 768) {

        $("input,select").keydown(function(e) {

            if (e.keyCode === 9) {
                if (($('form').find('.final').is(":focus")) || ($('form').find('.final *').is(":focus"))) {
                    e.preventDefault();
                    e.stopPropagation();
                } else {}
            }
        });


        if (!$('#fullpage').hasClass('fullpage-wrapper')) {
            initTheFullPageFun();
        }
    } else {
        if ($('#fullpage').hasClass('fullpage-wrapper')) {
            fullpage_api.destroy('all');
            $('#fullpage').removeClass('fullpage-wrapper')
        }

        setTimeout(function() {
            // console.log('destroy selectpicker')
            $('.selectpicker').selectpicker('destroy')
            $('.bootstrap-select').selectpicker('destroy')
        }, 500);

        $('#fullpage .section').addClass('active_section')
        $('.navigation-menu .menu a[href^="#"]').on('click', function(e) {
            e.preventDefault();
            var target = $(this).attr('mobile-menu-href');
            $target = $(target);
            console.log('$target', $target)
            $('.hamburger').removeClass('is-active');
            $('.navigation-menu').removeClass('is-active');
            $('.header').removeClass('is-active-menu');
            console.log($target.offset().top - 0)
            $('html.mainsite,html.mainsite  body').stop().animate({
                'scrollTop': $target.offset().top - 0 //no need of parseInt here
            }, 900, 'swing', function() {});
        });



        $(document).bind('scroll', function(e) {
            $('.hamburger').removeClass('is-active');
            $('.navigation-menu').removeClass('is-active');
            $('.header').removeClass('is-active-menu');
            $('#fullpage .section ,.contact-us ,.career').each(function() {
                if (
                    $(this).offset().top < window.pageYOffset + 10 &&
                    $(this).offset().top + $(this).height() > window.pageYOffset + 10

                ) {
                    var section_id = $(this).attr('id');

                    if (($('.navigation-menu .menu a[mobile-menu-href^="#' + section_id + '"]')).length > 0) {
                        $('.navigation-menu .menu a.active').removeClass('active')
                        $('.navigation-menu .menu a[mobile-menu-href^="#' + section_id + '"]').addClass('active');

                        window.location.hash = $('.navigation-menu .menu a[mobile-menu-href^="#' + section_id + '"]').attr('data-menuanchor');
                    }

                }
            });
        });


    }

}

// In the bundled script:
function reinitfullpage() {
    // initfullpage();
    // fullpage_api.reBuild();
    var active_section_index = fullpage_api.getActiveSection().index + 1;
    //console.log('active_section_index: is ', active_section_index)

    fullpage_api.destroy('all');





    let myFullpage = new fullpage('#fullpage', {



        resize: false,
        animateAnchor: false,
        scrollOverflow: true,
        autoScrolling: true,
        fitSection: false,
        anchors: menu_items,
        menu: '.navigation-menu .menu',
        navigation: false,
           scrollBar: true,
        fitToSection:false,
        onLeave: function(origin, destination, direction) {
            var params = {
                origin: origin,
                destination: destination,
                direction: direction
            };


            var sec_id = params['destination'].item.id;

            $('.hamburger').removeClass('is-active');
            $('.navigation-menu').removeClass('is-active');
            $('.header').removeClass('is-active-menu');





            setTimeout(function() {
                $('#' + sec_id).addClass('active_section');
                // $('.active_section .fp-scroller').removeClass('resetscroll');
            }, 400);

            if ($('#' + sec_id).hasClass('sliding-section')) {

                $('.header').removeClass('smallLogo').addClass('sliding-header');

            } else {

                $('.header').addClass('smallLogo').removeClass('sliding-header');

            }

            if ((($('#' + sec_id).hasClass('section-whth-white'))) || (($('#' + sec_id).hasClass('exhibitions-section')))) {
                $('.header').addClass('whitelogo')

            } else {
                $('.header').removeClass('whitelogo')
            }
            if ($('#' + sec_id).hasClass('career-contact-section')) {
                $('.header').addClass('two-links-active');
            } else {
                $('.header').removeClass('two-links-active');

            }


        }




    })






}
// // Bind to the window
window.reinitfullpage = reinitfullpage;

window.moveSectionUp = moveSectionUp;
window.moveSectionDown = moveSectionDown;


function moveSectionUp() {
    //e.preventDefault();
    fullpage_api.moveSectionUp();
}

function moveSectionDown() {
    //e.preventDefault();
    fullpage_api.moveSectionDown();
}


var hassmaalllogo = false;
$('.hamburger').click(function() {
    $('.header').removeClass('sliding-header-detect');
    $('.hamburger').toggleClass('is-active');
    $('.navigation-menu').toggleClass('is-active');
    $('.header').toggleClass('is-active-menu');

    if ($('.header').hasClass('smallLogo')) {
        hassmaalllogo = true;
        $('.header').removeClass('smallLogo');

    }

    if ((!($('.hamburger').hasClass('is-active'))) && (hassmaalllogo == true)) {
        hassmaalllogo = false;
        $('.header').addClass('smallLogo');

    }
    if ((!($('.hamburger').hasClass('is-active')))) {

        $('.header').addClass('sliding-header-detect');

    }



})

//$('body').css('paddingTop', ($('.navbar').innerHeight() / 1));



$(document).mousemove(function(e) {
    $('.parallax .top-img').parallax(-30, e)
    $('.parallax  .bottom').parallax(10, e)
    $('.parallax  .letter-main').parallax(20, e)
    $('.parallax  .letter-2').parallax(20, e)
});




$("body").delegate(".mrec-fileds", "change", function(e) {
    var parent_val = $(this).val();
    if (parent_val) {
        if (options_item.length > 0) {
            var options = '';
            for (var i = 0; i < options_item.length; i++) {
                if (options_item[i].parent_val == parent_val) {
                    options += "<option  value='" + options_item[i].value + "' class='" + options_item[i].parent_val + "'>" + options_item[i].name + "</option>"
                } else {}
            }
        }


        $('.field-help').find('option').remove();
        $('.field-help').attr('disabled', false);
        $('.field-help').prop("disabled", false)

        if ($('.field-help').hasClass('selectpicker')) {
            $('.field-help').selectpicker('refresh');
            $('.selectpicker.field-help').append(options).selectpicker();
            $('.selectpicker').selectpicker('refresh');
        } else {

            $('.field-help').append('<option  value="' + $(".field-help").attr('title') + '">please select</option>')
            $('.field-help').append(options)


        }



    }
});