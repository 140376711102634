/* src/app.js */

// Styles
// import "../node_modules/bootstrap/scss/bootstrap.scss";
import "./assets/styles/main-ltr.scss";


//Scriptsbootstrap-slider.scss
import 'bootstrap';
// import 'bootstrap/js/dist/util';
// import 'bootstrap/js/dist/dropdown';
// import Swiper from 'swiper';
require('./assets/scripts/loader');

$(document).ready(() => {

    require('./assets/scripts/scrolloverflow.min');
    require('./assets/scripts/custom');

    require('./assets/scripts/animation');
    // require('./assets/scripts/validation-localization');
    // require('./assets/scripts/e-motion-validate');
    require('./assets/scripts/auto-hide-header');
    require('./assets/scripts/TweenMax.min');
    require('./assets/scripts/jquery-parallax');
    // require('./assets/scripts/constraints');

    require('./assets/scripts/select');
    require('./assets/scripts/google_map');


});